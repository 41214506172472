import find from 'lodash/find'
import isFinite from 'lodash/find'
import data from '../data/dosages.json'

const isSelectionValid = ({ type, name, dose }) => {
  const errors = {}

  if (!name) {
    errors.name = 'This value is required'
  } else if (type && find(data.categories[type].items, { name }) === undefined) {
    errors.name = 'Unknown antipsychotic, please select a value from the list'
  }

  if (!dose) {
    errors.dose = 'This value is required'
  } else if (isFinite(dose) === false || dose <= 0) {
    errors.dose = 'Invalid dosage, please and a numeric value greater than zero'
  }

  return errors
}

export default isSelectionValid;
