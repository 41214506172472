import React from 'react'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import Calculation from './components/Calculation'
import Home from './routes/Home'
import Calculate from './routes/Calculate'
import About from './routes/About'
import './App.scss'

function App() {
  return (
    <main className='app'>
      <BrowserRouter>
        <Calculation>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/calculate' component={Calculate} />
            <Route path='/about' component={About} />
          </Switch>
          <div className='app__footer columns is-mobile'>
            <div className='column'>&nbsp;</div>
            <div className='column is-narrow'>
              <Link to='/about'>About</Link>
            </div>
          </div>
        </Calculation>
      </BrowserRouter>
    </main>
  );
}

export default App;
