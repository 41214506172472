import React, { useEffect } from 'react'
import find from 'lodash/find'
import classNames from 'classnames'
import useCalculation from '../UseCalculation'
import NameInput from '../NameInput'
import DoseInput from '../DoseInput'
import isSelectionValid from '../../utils/isSelectionValid'
import './AntipsychoticSelector.scss'

const AntipsychoticSelector = ({ id, type, category, className, children }) => {
  const { selected, updateType, updateName, updateDose, updateMax } = useCalculation()
  const errors = isSelectionValid(selected[id])
  const { name, dose } = selected[id]
  const doseMax = find(category.items, { name })

  useEffect(() => {
    updateType(id, type)
  }, [id, type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateMax(id, doseMax && doseMax.max)
  }, [doseMax && doseMax.max]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames('antipsychotic-selector', className)}>
      <div className='field'>
        <label className='label'>{category.name}</label>
        <div className='field-body'>
          <NameInput
            id={id}
            listItems={category.items}
            value={name || ''}
            error={errors.name}
            onChange={e => updateName(id, e.target.value)}
            className='antipsychotic-selector__name' />
          <DoseInput
            id={id}
            value={dose || ''}
            period={category.period}
            doseMax={doseMax && doseMax.max}
            error={errors.dose}
            onChange={e => updateDose(id, parseFloat(e.target.value))}
            className='antipsychotic-selector__dose' />
        </div>
      </div>
      {children}
    </div>
  )
}

export default AntipsychoticSelector
