import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import useCalculation from '../../components/UseCalculation';
import './Calculate.scss'

const round = (num, precision = 2) => Number(num.toFixed(precision))

const sumSelected = arr => {
  const sum = arr.reduce((total, { dose, max }) => {
    return total +  dose / max
  }, 0)

  return sum * 100
}

const Calculate = () => {
  const [showWorking, setShowWorking] = useState(false)
  const { selectedArr } = useCalculation()
  if (selectedArr.length === 0) {
    return (
      <Redirect to='/' />
    )
  }

  const total = sumSelected(selectedArr)
  const totalRounded = Math.ceil(total)
  const classes = {
    'is-info': totalRounded <= 100,
    'is-danger': totalRounded > 100
  }

  return (
    <div className={classNames('calculate hero is-fullheight', classes)}>
      <div className='hero-head'>&nbsp;</div>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <h1 className='title is-1'>{totalRounded}%</h1>
          {total > 100 &&
            <h2 className='subtitle is-3'>HDAT</h2>
          }
          <button
            onClick={() => setShowWorking(!showWorking)}
            className='button is-text is-transparent is-borderless has-text-white'>
            {showWorking ? 'Hide working' : 'Show working'}
          </button>
          {showWorking &&
            <div className='calculate__working'>
              {selectedArr.map(({ id, name, max, dose }) => (
                <div key={id} className='level is-mobile'>
                  <div className='level-left'>
                    <p>{name} {dose}mg/{max}mg</p>
                  </div>
                  <div className='level-right'>
                    <p>{round((dose/max) * 100)}%</p>
                  </div>
                </div>
              ))}
              <hr />
              <div className='level is-mobile'>
                <div className='level-left'>&nbsp;</div>
                <div className='level-right'>
                  <p><strong className='has-text-white'>Total: {round(total)}%</strong></p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className='hero-foot'>
        <div className='container'>
          <div className='buttons are-medium is-centered'>
            <Link
              to='/'
              className='button is-white'>
              Start again
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calculate
