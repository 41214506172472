import React, { useState } from 'react'
import classNames from 'classnames'

const DepotSelector = ({ period, doseMax, error, className, ...rest }) => {
  const [touched, setTouched] = useState(false)
  return (
    <div className={classNames('field is-narrow', className)}>
      <div className='field has-addons has-addons-centered'>
        <p className='control'>
          <input
            type='number'
            placeholder='Dose'
            {...rest}
            onBlur={() => setTouched(true)}
            min={0}
            className='input is-rounded' />
        </p>
        <p className='control'>
          <button
            tabIndex={-1}
            className='button is-static is-rounded has-background-white has-text-black'>mg/{period}</button>
        </p>
      </div>
      {doseMax &&
        <p className='help has-text-centered-touch'>BNF max is {doseMax} mg/{period}</p>
      }
      {touched && error &&
        <p className='help has-text-centered-touch is-danger'>{error}</p>
      }
    </div>
  )
}

export default DepotSelector
