import React, { useState } from 'react'
import classNames from 'classnames'
import map from 'lodash/map'
import AntipsychoticSelector from '../AntipsychoticSelector'
import data from '../../data/dosages.json'

const CategorySelector = ({ id, className, children }) => {
  const [categoryType, setCategoryType] = useState()
  const categoryClass = classNames('category', className)

  if (!categoryType) {
    return (
      <div className={categoryClass}>
        {children}
        <div className='buttons are-medium is-centered'>
          {map(data.categories, ({ name }, catId) => (
            <button key={catId} onClick={() => setCategoryType(catId)} className='button is-rounded'>
              {name}
            </button>
          ))}
        </div>
      </div>
    )
  }
  return (
    <AntipsychoticSelector
      id={id}
      category={data.categories[categoryType]}
      type={categoryType}
      className={categoryClass}>
      {children}
    </AntipsychoticSelector>
  )
}

export default CategorySelector
