import React, { useState } from 'react'

const NameInput = ({ id, listItems, error, className, ...rest }) => {
  const [touched, setTouched] = useState(false)
  return (
    <div className='field'>
      <p className='control is-expanded'>
        <input
          {...rest}
          onBlur={() => setTouched(true)}
          list={`list-items-${id}`}
          spellCheck='false'
          placeholder='Start typing...'
          className='input is-rounded' />
        <datalist id={`list-items-${id}`}>
          {listItems.map(({ name }) => (
            <option key={name} value={name} />
          ))}
        </datalist>
      </p>
      {touched && error &&
        <p className='help is-danger'>{error}</p>
      }
    </div>
  )
}

export default NameInput
