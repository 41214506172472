import React, { createContext, useReducer } from 'react'
export const CalculationContext = createContext({
  selected: []
})

const initialState = {
  selectedOrder: [],
  selected: {}
}

function reducer (state, { type, payload }) {
  switch (type) {
    case 'addSelected':
      return {
        ...state,
        selectedOrder: [
          ...state.selectedOrder,
          payload.id
        ],
        selected: {
          ...state.selected,
          [payload.id]: {
            id: payload.id
          }
        }
      }
    case 'updateSelected': {
      const item = state.selected[payload.id]
      return {
        ...state,
        selected: {
          ...state.selected,
          [payload.id]: {
            ...item,
            ...payload
          }
        }
      }
    }
    case 'removeSelected':
      return {
        ...state,
        selectedOrder: state.selectedOrder.filter(id => id !== payload.id),
        selected: {
          ...state.selected,
          [payload.id]: undefined
        }
      }
    case 'reset':
      return initialState
    default:
      throw new Error()
  }
}

const Calculation = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedArr = state.selectedOrder.map(id => state.selected[id])
  const addSelected = id => dispatch({ type: 'addSelected', payload: { id } })
  const updateType = (id, type) => dispatch({ type: 'updateSelected', payload: { id, type } })
  const updateName = (id, name) => dispatch({ type: 'updateSelected', payload: { id, name } })
  const updateMax = (id, max) => dispatch({ type: 'updateSelected', payload: { id, max } })
  const updateDose = (id, dose) => dispatch({ type: 'updateSelected', payload: { id, dose } })
  const removeSelected = id => dispatch({ type: 'removeSelected', payload: { id } })
  const reset = () => dispatch({ type: 'reset' })

  return (
    <CalculationContext.Provider value={{
      selectedArr,
      selected: state.selected,
      addSelected,
      updateType,
      updateName,
      updateMax,
      updateDose,
      removeSelected,
      reset
    }}>
      {children}
    </CalculationContext.Provider>
  )
}

export default Calculation
