import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div className='about section content'>
      <h2>Authors</h2>
      <p>Concept created by Dr. Christopher Lawrence. Website created by William Hawker</p>

      <h2>Disclaimer</h2>
      <p className='is-size-7'>Last updated: 16th November 2020</p>

      <p>The medical information is provided for general informational and educational purposes only and is not a substitute for professional advice.</p>

      <p>The application authors have no official association with the BNF (British National Formulary).</p>

      <p>All information on the application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the application.</p>

      <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the application or reliance on any information provided on the application.</p>

      <p>Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. The use or reliance of any information contained on this application is solely at your own risk.</p>

      <p><Link to='/'>Return home</Link></p>
    </div>
  )
}

export default About;
