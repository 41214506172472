import React, { useEffect } from 'react'
import useCalculation from '../../components/UseCalculation'
import CategorySelector from '../../components/CategorySelector'
import CalculateButton from '../../components/CalculateButton'
import './Home.scss'

const Home = () => {
  const { selectedArr, addSelected, removeSelected, reset } = useCalculation()

  useEffect(() => {
    reset()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='home'>
      <div className='home__actions container is-fluid'>
        <h1 className='is-size-3'>HDAT Helper</h1>
      </div>
      {selectedArr.map(({ id }) => (
        <CategorySelector key={id} id={id} className='home__item container is-fluid'>
          <button
            onClick={() => removeSelected(id)}
            title='Remove this row'
            className='home__item-delete delete is-medium'>
            &nbsp;
          </button>
        </CategorySelector>
      ))}
      <div className='home__actions container is-fluid'>
        <div className='buttons are-medium is-centered'>
          <button
            onClick={() => addSelected(`item-${selectedArr.length}`)}
            title='Add a new row'
            className='button is-rounded is-fullwidth is-info'>
            Add
          </button>
          <CalculateButton>Calculate!</CalculateButton>
        </div>
      </div>
    </div>
  )
}

export default Home;
