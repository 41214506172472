import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'
import useCalculation from '../UseCalculation'
import isSelectionValid from '../../utils/isSelectionValid'

const CalculateButton = ({ children }) => {
  const [disabled, setDisabled] = useState(true)
  const { selectedArr } = useCalculation()
  const { push } = useHistory();

  useEffect(() => {
    if (selectedArr.length === 0) {
      setDisabled(true)
      return
    }

    const isInvalid = selectedArr.some(item => {
      return size(isSelectionValid(item)) > 0
    })
    setDisabled(isInvalid)
  }, [selectedArr])

  const handleClick = useCallback(() => {
    push('/calculate')
  }, [push])

  return (
    <button
      onClick={handleClick}
      title='Calculate the result'
      disabled={disabled}
      className='button is-rounded is-fullwidth is-success'>
      {children}
    </button>
  )
}

export default CalculateButton
